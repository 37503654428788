<template>
  <div id="main">
      <div id="manu" alt="">
        <navigation />
      </div>
      <div id="contentBox">
        <!-- 背景 -->
        <div class="registervideoContainer" alt=""></div>
        <div src="../assets/img/01.jpg" alt=""></div>
        <div class="w">
          <!-- 搜索 -->
          <div class="shousuo">
            <input type="text" class="input__search" v-model="keywords" @keydown.enter="search" @keyup="getData($event)" @focus="isShow = true" @blur="handleBlur" placeholder="搜索......">
            <button @click="search">
              <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" height="20px" width="20px" alt="">
                <path d="M4 9a5 5 0 1110 0A5 5 0 014 9zm5-7a7 7 0 104.2 12.6.999.999 0 00.093.107l3 3a1 1 0 001.414-1.414l-3-3a.999.999 0 00-.107-.093A7 7 0 009 2z" fill-rule="evenodd" fill="#17202A" alt=""></path>
              </svg>
            </button>
            
            <div v-if="result.length" class="search-results">
              <ul>
                <li v-for="item in result" :key="item.id" >
                  <a :href="item.url">{{ item.title }}</a>
                </li>
              </ul>
            </div>
            <!-- && isShow -->
            <div v-if="data.length && isShow" class="autocomplete-results">
              <ul>
                <li v-for="item in data" :key="item.id" @click="goToPage(item.url)">
                  {{ item.title }}
                </li>
              </ul>
            </div>
          </div>
          <!-- 公告 -->
          <div>
          <h3 class="resource">免费资源&nbsp;&nbsp;&nbsp;&nbsp;视频&nbsp;&nbsp;软件&nbsp;&nbsp;&nbsp;代码</h3>
          <div>
            <div class="Version_cover" v-if="VersionFlag">
              <div class="center_box">
                <h3>发现新版本</h3>
                <div class="explain" v-html="VersionExplain"></div>
                <div class="btn" @click="upgrade">前往升级</div>
                <div class="close-btn" @click="closeVersionCover">&times;</div> <!-- 关闭按钮 -->
              </div>
            </div>
          </div>
          <!-- 标签云 -->
          <div>
            <div class="tag-cloud">
            <h2 class="tag-cloud-1">标签云</h2>
            <router-link
              v-for="(tag, index) in tags"
              :key="index"
              :to="tag.link"
              class="tag-item"
            >
              {{ tag.name }}
            </router-link>
          </div>
          </div>
        </div>
        <!-- 内容 -->
        <div class="top">
          <h1>漫画专区</h1>
        </div>
        <div class="image-container">
          <router-link to="/miaoqu">
            <img src="../assets/zy/1.jpg" class="miaoqu" />
          </router-link>
          <router-link to="/kaobei">
            <img src="../assets/zy/2.jpg" class="kaobei" />
          </router-link>
        </div>
        <!-- 内容 -->
        <div class="game">
          <h1>游戏专区</h1>
        </div>
        <div class="game-container">
          <a href="https://pan.baidu.com/s/1pIfFggXc7P3eN2Bqz2EbNA?pwd=kzjg" class="sot" target="_blank" >【PC】[ACT][官中][步兵]血族少女也能当圣骑士吗v1.0</a>
          <a href="https://pan.baidu.com/s/10v56iz_iD8azAO75zEPtbw?pwd=kzjg" class="sot1" target="_blank" >【PC】[ACT][官中]棘罪修女 v0.6.5</a>
          <a href="https://pan.baidu.com/s/13hHjkqcrm7iQUtjExvqI0A?pwd=kzjg" class="sot2" target="_blank" >【PC】[ACT]战争公主 battle princess 10月更新版</a>
          <a href="https://pan.baidu.com/s/14shWrGGRBkrHNArKPqAnng?pwd=kzjg" class="sot3" target="_blank" >【PC】魔法少女春日的绝妙成长！ </a>
          <a href="https://pan.baidu.com/s/1tUOuNTjnc3vByPOurD9LQw?pwd=kzjg" class="sot4" target="_blank" >【PC】异世界战线ISEKAI FRONTLINE Demo </a>
          <a href="https://pan.baidu.com/s/1MCsnw0mingmzkv1_tWq_2g?pwd=lnd5" class="sot5" target="_blank" >【PC+安卓】[ACT]变幻装姬闪耀幻影！V1.04 云翻汉化版 + 动画中文漫画  </a>
          <a href="https://pan.baidu.com/s/1B8B6gpGrIONqlXKAq-BKbw?pwd=kzjg" class="sot6" target="_blank" >【PC+安卓】[ACT]萨哈塔遭遇的一日 シャハタ遭難の一日 v1.0.5 </a>
        </div>
        <Pfooter/>
        </div>
      </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      htmlContent: `
        <h1>Welcome to My Web Page</h1>
        <p>This is a simple HTML page embedded in a Vue component.</p>
        <a href="#" target="_blank">Visit Example.com</a>
      `,
      showPopup: false,
      goodsList: [],
      keywords: '',
      searchResults: [],
      result: [],
      data: [],
      // 跟随鼠标加载，否则false
      isShow: true,
      // 添加这个标志来跟踪页面是否已经加载
      sPageLoaded: false,
      searchHistory: [],
      //
      VersionFlag: false,
      VersionExplain: '',
      currentVersion: '1.0', // 当前版本
      versionFileUrl: '', // 存储文件下载链接
      // 标签云
      tags: [
        { name: '前端', link: '/Front' },
        { name: '软件站', link: '/Front' },
        { name: '后端', link: '/back' },
        { name: '服务器', link: '/O&M' },
        { name: '数据库', link: '/Nav' },
        { name: 'frp', link: '/frp' },
        { name: '内网穿透', link: '/frp' },
        { name: '梯子', link: '/ront' },
        { name: '魔法', link: '/ront' },
        { name: 'vpn', link: '/ront' },
        { name: '视频解析', link: '/vipvideo'},
        { name: '加速器', link: '/accelerator'},
      ],
    };
  },
  mounted() {
    // 页面加载完成后设置为 true 否则false
    this.isPageLoaded = true; 
  },
  methods: {
    togglePopup() {
      this.showPopup = !this.showPopup;
    },
    search() {
    this.result = [];
    this.isShow = false;
    
    // 向后端发送搜索请求
    // https://s.900191.xyz/api
    // http://192.168.3.18:3000
    axios.get('https://s.900191.xyz/api/search', {
      params: { keywords: this.keywords }
    })
    .then(response => {
      this.result = response.data; // 假设每个结果都有一个 `url` 字段
      if (!this.searchHistory.includes(this.keywords)) {
        this.searchHistory.push(this.keywords);
      }
      // 路由跳转逻辑 (这个可以移除, 因为跳转会在点击结果时处理)
      // this.$router.push({ name: 'SearchResults', query: { keywords: this.keywords } });
    })
    .catch(error => {
      console.error('搜索请求失败:', error);
    });
  },
  getData(event) {
      if (!this.isPageLoaded) return; // 页面未加载完毕时不执行任何操作

      if (event.keyCode === 13) {
        this.isShow = false;
        return;
      }

      this.isShow = true;
      // ！！！内外网测试改ip！！！
      // 向后端发送实时搜索请求，获取动态提示
      // https://s.900191.xyz/api
      axios.get('https://s.900191.xyz/api/search', {
        params: { keywords: this.keywords }
      })
      .then(response => {
        this.data = response.data;
      })
      .catch(error => {
        console.error('实时搜索请求失败:', error);
      });
    },
    handleBlur() {
    // 延迟隐藏以处理点击事件
    setTimeout(() => {
        this.isShow = false;
      }, 100);
    },
    ressetSearch(kw) {
      this.keywords = kw;
      this.isShow = false;
      this.search();
    },
    goToPage(url) {
      this.isShow = false; // 先隐藏自动完成结果
      this.$router.push(url);
    },
    //
    checkVersion() {
      // 外网服务器frp已开
      // 如未开要记得改ip+端口  连同数据库！！！
      // Vue Native   app开发包
      // 外网部署https://a.410121.xyz/api/check-version
      // 内网测试http://localhost:2000/api/check-version
      fetch('https://s.900191.xyz/api/check-version')
        .then(response => response.json())
        .then(data => {
          if (data.version !== this.currentVersion) {
            this.VersionExplain = data.description;
            this.versionFileUrl = data.fileUrl; // 将文件下载链接存储在Vue组件的变量中
            this.VersionFlag = true; // 显示版本更新提示
          }
        })
        .catch(error => console.error('Error fetching version:', error));
    },
    upgrade() {
      // 在这里实现前往升级的逻辑，例如直接下载文件
      const link = document.createElement('a');
      link.href = this.versionFileUrl;
      link.download = 'uno.apk'; // 下载文件名
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // 提示用户安装
      alert('下载完成，请手动安装新版本!!!');
    },
    closeVersionCover() {
      this.VersionFlag = false; // 关闭版本更新提示
    },
  },
  created() {
    this.checkVersion();
    // 可以使用 setInterval 定期检查新版本，例如每小时检查一次
    // setInterval(this.checkVersion, 3600000);
  }
}
</script>

<style scoped>
/* 版心 */
.w {
	width: 1700px;
	margin: 0 auto;
}
/*  */
img{
  width: 100%;
  height: 100%;
  display: block;
}
#home {
  width: 100%;
}
#contentBox {
  width: 100%;
  height: 100%;
  position: relative;
  background-repeat: no-repeat;
  overflow: auto; 
  z-index: 0;
}
.pic,
.des {
  overflow: auto;
  color: var(--home-pic-des-color);
  font-size: 10px;
}
.registervideoContainer {
  background-size: cover;
  background-repeat: no-repeat;
  /* //保证盒子始终在窗口中心 */
  background-position: center;
  /* //避免窗口周围留白 */
  margin-left: 0rem;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
  background-image: url(../assets/2.jpg);
  /* 增加虚化效果，参数可以根据需要调整 */
  filter: blur(6px);
}
.resource{
  margin-left: 38%;
  font-size: 35px;
  color: #054e61b4;
}
.top {
  margin-top: 20px; /* 调整为更小的值，更自然 */
  margin-left: 13%;
  margin-top: -10%;
}
.image-container {
  display: flex;
  justify-content: space-around; /* 横向排列并均匀分布 */
  align-items: center;
  margin-top: 20px; /* 增加顶部间距 */
}
.miaoqu{
  height: 150px;
  width: 300px;
  margin-left: -65%;
  margin-top: -3%;
}
.kaobei{
  height: 150px;
  width: 300px;
  margin-left: -50rem;
}
.game{
  margin-top: 20px; /* 调整为更小的值，更自然 */
  margin-left: 55%;
  margin-top: -12%;
}
.sot, .sot1, .sot2, .sot3, .sot4, .sot5, .sot6, .sot7 {
  height: 150px;
  width: 500px;
  margin-left: 54%;
  margin-top: 0%;
  border: 1px solid red;
  background-color: rgba(255, 0, 0, 0.1);
  z-index: 10; /* 确保其在上方 */
  position: relative; /* 设置z-index时需要添加position */
  
  word-wrap: break-word; /* 超出时自动换行 */
  overflow-wrap: break-word; /* 支持浏览器的换行 */
  white-space: normal; /* 允许文本折行 */
}


/* 基础样式 */
.image-container {
  position: relative;
}

.thumbnail {
  width: 100%;
  height: auto;
  cursor: pointer;
  transition: transform 0.3s ease; /* 动画效果 */
}

/* 放大样式 */
.thumbnail.active {
  transform: scale(2); /* 放大倍数 */
  z-index: 1000; /* 确保放大图片在顶层显示 */
}

.price{
  color: red;
  text-align: left;
  margin: 10px 0;
  font-size: 18px;
  background: var(--input-right-bottom-color);
  padding: 10px;
  box-sizing: border-box;
}
.describe1,
.describe2{
  text-align: left;
  font-size: 14px;
  margin-bottom: 10px;
  background: #11369e;
  padding:5px;
  box-sizing: border-box;
}
.describe1 a,
.describe2 a{
  color: var(--input-right-bottom-color);
  display: block;
  line-height: 30px;
}
/* 弹窗 */
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  padding: 20px;
  background: var(--input-right-bottom-color);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  z-index: 1000;
}
#popup{
  margin-top: -2%;
  margin-left: 80%;
}
/* 弹窗内容样式 */
.popup-content {
  position: relative;
  text-align: center;
}

/* 关闭按钮样式 */
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

/* 背景遮罩样式 */
.popup::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: -1;
}
/*  */
.shousuo {
  margin-left: 6%;
  display: flex;
  align-items: center;
}

.shousuo input {
    width: 200px;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    margin-right: 10px;
    border-radius: 10px;
    background-color: #999cff;
    background-image: radial-gradient(at 85% 51%, hsl(313deg 20.99% 83.24%) 0px, transparent 110%), 
    radial-gradient(at 74% 68%, hsla(235, 69%, 77%, 1) 0px, #00000000 50%), 
    radial-gradient(at 64% 79%, hsl(284deg 25.64% 71.61% / 67%) 0px, transparent 30%), 
    radial-gradient(at 75% 16%, hsla(283, 60%, 72%, 1) 0px, transparent 50%), 
    radial-gradient(at 90% 65%, hsla(153, 70%, 64%, 1) 0px, transparent 50%), 
    radial-gradient(at 91% 83%, hsla(283, 74%, 69%, 1) 0px, #00000000 50%), 
    radial-gradient(at 72% 91%, hsl(210.98deg 93.99% 21.59% / 96%) 0px, transparent 50%);
}
.input__search {
  width: 100%;
  border-radius: 20px;
  outline: none;
  border: none;
  padding: 8px;
  position: relative;
}
.shousuo button {
  padding: 5px 12px;
  margin-left: -0.5%;
  font-size: 16px;
  color: var(--input-right-bottom-color);
  background-color: #4bd5db;
  border: none;
  border-radius: 9px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.shousuo button:hover {
  background-color: #00a7b38c;
}

.search-results,
.autocomplete-results {
  position: absolute;
  top: 5%;
  left: 11%;
  background: white;
  box-shadow: 0 0 10px rgba(51, 227, 240, 0.5);
  border: 1px solid #ccc;
  border-radius: 4px;
  border-top: none;
  z-index: 1000; /* 确保结果悬浮在其他内容之上 */
  width: 215px;
  max-height: 300px; /* 设置最大高度，避免内容过长 */
  overflow-y: auto; /* 当内容超出时，出现滚动条 */
}

.search-results ul,
.autocomplete-results ul {
  /* list-style: none; */
  margin: 0;
  padding: 0;
}

.search-results li,
.autocomplete-results li {
  padding: 8px;
  cursor: pointer;
}

.search-results li:hover,
.autocomplete-results li:hover {
  background-color: #f0f0f0;
}
/*  */
.avatar {
  margin-top: -1.1%;
  margin-left: 90%;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
}
.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* *
* *
***
 */
input,
textarea {
  user-select: auto;
}

.addCard .van-field__control {
  color: #b1b1b1;
}

.Version_cover {
  width: 100%;
  height: 100vh;
  background: #000000b3;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;

  .center_box {
    width: 15%;
    height: 15rem;
    border-radius: 0.533333rem;
    background: #7bbfd4af;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    h3 {
      width: 100%;
      text-align: center;
      font-size: 2rem;
      font-weight: bold;
      padding: 0.266666rem;
    }

    .explain {
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      width: 100%;
      box-sizing: border-box;
      font-size: 1.2rem;
      opacity: 0.9;
    }

    .btn {
      width: 80%;
      height: 3rem;
      background: #ff4400b6;
      border-radius: 0.533333rem;
      font-size: 2rem;
      color: #ffffff;
      line-height: 3.066666rem;
      text-align: center;
      letter-spacing: 1px;
      position: absolute;
      bottom: 0.266666rem;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 33px;
  color: #000000;
}
/*  */
.tag-cloud {
  position: relative;
  transform: translate(480%, 325%);
  width: 295px;
  padding: 5px;
  background: var(--home-tag-background-color);
  box-shadow: 0 0 10px var(--home-tag-color);
  border-radius: 8px;
  z-index: 1000;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.tag-cloud a{
  font-size: 15px;
}
.tag-cloud-1 {
  margin-left: 55%;
  transform: translate(-62%, 5%);
}
.tag-item {
  padding: 5px 10px;
  border: 1px solid var(--home-tag-item-color);
  border-radius: 4px;
  background-color: var(--home-tag-item-color);
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.tag-item:hover {
  background-color: var(--home-tag-item-color);
  color: var(--home-pic-des-color);
}
/* 大型设备（桌面，1024px以上） */
@media screen and (max-width: 1600px) {
  .w {
    max-width: 1400px;
  }
  .registervideoContainer {
    margin-left: -3.5rem;
  }
  .home_jineng {
    display: flex;
    width: 100%;
    margin-left: -15%;
    margin-top: -30%;
  }
  .home_jineng > li {
    width: 80%;
    margin-left: 50px;
    margin-top: 15px;
    background: var(--home-li-color);
    box-shadow: 0 0 10px var(--home-box-li-color);
    text-align: '';
    color: var(--input-right-bottom-color);
    cursor: pointer;
    transition: all 0.5s;
    box-sizing: border-box;
  }
  .home_jineng > li .pic{
    width: 100%;
    height: 128px;
    background: var(--input-right-bottom-color);
  }
  .html {
    display: flex;
    width: 100%;
    margin-left: -15%;
    margin-top: -17%;
  }
  .html > li {
    width: 80%;
    margin-left: 50px;
    margin-top: 15px;
    background: var(--home-li-color);
    box-shadow: 0 0 10px var(--home-box-li-color);
    text-align: '';
    color: var(--input-right-bottom-color);
    cursor: pointer;
    transition: all 0.5s;
    box-sizing: border-box;
  }
  .html > li .des{
    width: 100%;
    height: 128px;
    background: var(--input-right-bottom-color);
  }
  .Blender {
    display: flex;
    width: 100%;
    margin-left: -15%;
    margin-top: -17%;
  }
  .Blender > li {
    width: 80%;
    margin-left: 50px;
    margin-top: 15px;
    background: var(--home-li-color);
    box-shadow: 0 0 10px var(--home-box-li-color);
    text-align: '';
    color: var(--input-right-bottom-color);
    cursor: pointer;
    transition: all 0.5s;
    box-sizing: border-box;
  }
  .Blender > li .des{
    width: 100%;
    height: 128px;
    background: var(--input-right-bottom-color);
  }
  .n {
    display: flex;
    width: 100%;
    margin-left: -15%;
    margin-top: -17%;
    margin-bottom: 20%;
  }
  .n > li {
    width: 80%;
    margin-left: 50px;
    margin-top: 15px;
    background: var(--home-li-color);
    box-shadow: 0 0 10px var(--home-box-li-color);
    text-align: '';
    color: var(--input-right-bottom-color);
    cursor: pointer;
    transition: all 0.5s;
    box-sizing: border-box;
  }
  .n > li .des{
    width: 100%;
    height: 128px;
    background: var(--input-right-bottom-color);
  }
  .Crime {
    display: flex;
    width: 100%;
    margin-left: -15%;
    margin-top: -17%;
    margin-bottom: 20%;
  }
  .Crime > li {
    width: 80%;
    margin-left: 50px;
    margin-top: 15px;
    background: var(--home-li-color);
    box-shadow: 0 0 10px var(--home-box-li-color);
    text-align: '';
    color: var(--input-right-bottom-color);
    cursor: pointer;
    transition: all 0.5s;
    box-sizing: border-box;
  }
  .Crime > li .des{
    width: 100%;
    height: 128px;
    background: var(--input-right-bottom-color);
  }
  .ps {
    display: flex;
    width: 100%;
    margin-left: -15%;
    margin-top: -17%;
    margin-bottom: 30%;
  }
  .ps > li {
    width: 80%;
    margin-left: 50px;
    margin-top: 15px;
    background: var(--home-li-color);
    box-shadow: 0 0 10px var(--home-box-li-color);
    text-align: '';
    color: var(--input-right-bottom-color);
    cursor: pointer;
    transition: all 0.5s;
    box-sizing: border-box;
  }
  .ps > li .des{
    width: 100%;
    height: 128px;
    background: var(--input-right-bottom-color);
  }
  /* 标签云 */
  .tag-cloud {
    position: relative;
    transform: translate(309%, 974%);
    width: 295px;
    padding: 5px;
    background: var(--home-tag-background-color);
    box-shadow: 0 0 10px var(--home-tag-color);
    border-radius: 8px;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20%;
  }
}
/*  */
@media screen and (max-width: 1400px) {
  .w{
    max-width: 1200px;
  }
  .registervideoContainer {
    margin-left: -3.5rem;
  }
  .home_jineng {
    display: flex;
    width: 100%;
    margin-left: -15%;
    margin-top: -30%;
    margin-bottom: 0%;
  }
  .home_jineng > li {
    width: 80%;
    margin-left: 160px;
    margin-top: 15px;
    background: var(--home-li-color);
    box-shadow: 0 0 10px var(--home-box-li-color);
    text-align: '';
    color: var(--input-right-bottom-color);
    cursor: pointer;
    transition: all 0.5s;
    box-sizing: border-box;
  }
  .home_jineng > li .pic{
    width: 100%;
    height: 128px;
    background: var(--input-right-bottom-color);
  }
  .html {
    display: flex;
    width: 100%;
    margin-left: -15%;
    margin-top: 17px;
    margin-bottom: 0%;
  }
  .html > li {
    width: 80%;
    margin-left: 160px;
    margin-top: 15px;
    background: var(--home-li-color);
    box-shadow: 0 0 10px var(--home-box-li-color);
    text-align: '';
    color: var(--input-right-bottom-color);
    cursor: pointer;
    transition: all 0.5s;
    box-sizing: border-box;
  }
  .html > li .des{
    width: 100%;
    height: 128px;
    background: var(--input-right-bottom-color);
  }
  .Blender {
    display: flex;
    width: 100%;
    margin-left: -15%;
    margin-top: 0.5%;
    margin-bottom: 10%;
  }
  .Blender > li {
    width: 80%;
    margin-left: 160px;
    margin-top: 15px;
    background: var(--home-li-color);
    box-shadow: 0 0 10px var(--home-box-li-color);
    text-align: '';
    color: var(--input-right-bottom-color);
    cursor: pointer;
    transition: all 0.5s;
    box-sizing: border-box;
  }
  .Blender > li .des{
    width: 100%;
    height: 128px;
    background: var(--input-right-bottom-color);
  }
  /*  */
  .shousuo{
    margin-left: 11%;
  }
  /*  */
  .n {
    display: flex;
    width: 100%;
    margin-left: -15%;
    margin-top: 0.5%;
    margin-bottom: 20%;
  }
  .n > li {
    width: 80%;
    margin-left: 160px;
    margin-top: 15px;
    background: var(--home-li-color);
    box-shadow: 0 0 10px var(--home-box-li-color);
    text-align: '';
    color: var(--input-right-bottom-color);
    cursor: pointer;
    transition: all 0.5s;
    box-sizing: border-box;
  }
  .n > li .des{
    width: 100%;
    height: 128px;
    background: var(--input-right-bottom-color);
  }
  .Crime {
    display: flex;
    width: 100%;
    margin-left: -15%;
    margin-top: -8%;
    margin-bottom: 20%;
  }
  .Crime > li {
    width: 80%;
    margin-left: 160px;
    margin-top: 15px;
    background: var(--home-li-color);
    box-shadow: 0 0 10px var(--home-box-li-color);
    text-align: '';
    color: var(--input-right-bottom-color);
    cursor: pointer;
    transition: all 0.5s;
    box-sizing: border-box;
  }
  .Crime > li .des{
    width: 100%;
    height: 128px;
    background: var(--input-right-bottom-color);
  }
  .ps {
    display: flex;
    width: 100%;
    margin-left: -15%;
    margin-top: -18%;
    margin-bottom: 30%;
  }
  .ps > li {
    width: 80%;
    margin-left: 160px;
    margin-top: 15px;
    background: var(--home-li-color);
    box-shadow: 0 0 10px var(--home-box-li-color);
    text-align: '';
    color: var(--input-right-bottom-color);
    cursor: pointer;
    transition: all 0.5s;
    box-sizing: border-box;
  }
  .ps > li .des{
    width: 100%;
    height: 128px;
    background: var(--input-right-bottom-color);
  }
  /* 标签云 */
  .tag-cloud {
    position: relative;
    transform: translate(270%, 930%);
    width: 295px;
    padding: 5px;
    background: var(--home-tag-background-color);
    box-shadow: 0 0 10px var(--home-tag-color);
    border-radius: 8px;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20%;
  }
  .shousuo{
    margin-top: 1%;
    margin-left: 15%;
  }
}
@media screen and (max-width: 1200px) {
  .w{
    max-width: 1000px;
  }
  .registervideoContainer {
    margin-left: -3.5rem;
  }
  .home_jineng {
    display: flex;
    width: 100%;
    margin-left: -15%;
    margin-top: -35%;
  }
  .home_jineng > li {
    width: 80%;
    margin-left: 50px;
    margin-top: 15px;
    background: var(--home-li-color);
    box-shadow: 0 0 10px var(--home-box-li-color);
    text-align: '';
    color: var(--input-right-bottom-color);
    cursor: pointer;
    transition: all 0.5s;
    box-sizing: border-box;
  }
  .home_jineng > li .pic{
    width: 100%;
    height: 128px;
    background: var(--input-right-bottom-color);
  }
  .html {
    display: flex;
    width: 100%;
    margin-left: -15%;
    margin-top: 17px;
  }
  .html > li {
    width: 80%;
    margin-left: 50px;
    margin-top: 15px;
    background: var(--home-li-color);
    box-shadow: 0 0 10px var(--home-box-li-color);
    text-align: '';
    color: var(--input-right-bottom-color);
    cursor: pointer;
    transition: all 0.5s;
    box-sizing: border-box;
  }
  .html > li .des{
    width: 100%;
    height: 128px;
    background: var(--input-right-bottom-color);
  }
  .Blender {
    display: flex;
    width: 100%;
    margin-left: -15%;
    margin-top: 0.5%;
  }
  .Blender > li {
    width: 80%;
    margin-left: 50px;
    margin-top: 15px;
    background: var(--home-li-color);
    box-shadow: 0 0 10px var(--home-box-li-color);
    text-align: '';
    color: var(--input-right-bottom-color);
    cursor: pointer;
    transition: all 0.5s;
    box-sizing: border-box;
  }
  .Blender > li .des{
    width: 100%;
    height: 128px;
    background: var(--input-right-bottom-color);
  }
  .n {
    display: flex;
    width: 100%;
    margin-left: -15%;
    margin-top: 0.5%;
    margin-bottom: 20%;
  }
  .n > li {
    width: 80%;
    margin-left: 50px;
    margin-top: 15px;
    background: var(--home-li-color);
    box-shadow: 0 0 10px var(--home-box-li-color);
    text-align: '';
    color: var(--input-right-bottom-color);
    cursor: pointer;
    transition: all 0.5s;
    box-sizing: border-box;
  }
  .n > li .des{
    width: 100%;
    height: 128px;
    background: var(--input-right-bottom-color);
  }
  .Crime {
    display: flex;
    width: 100%;
    margin-left: -15%;
    margin-top: -10%;
    margin-bottom: 20%;
  }
  .Crime > li {
    width: 80%;
    margin-left: 50px;
    margin-top: 15px;
    background: var(--home-li-color);
    box-shadow: 0 0 10px var(--home-box-li-color);
    text-align: '';
    color: var(--input-right-bottom-color);
    cursor: pointer;
    transition: all 0.5s;
    box-sizing: border-box;
  }
  .Crime > li .des{
    width: 100%;
    height: 128px;
    background: var(--input-right-bottom-color);
  }
  .ps {
    display: flex;
    width: 100%;
    margin-left: -15%;
    margin-top: -19%;
    margin-bottom: 30%;
  }
  .ps > li {
    width: 80%;
    margin-left: 50px;
    margin-top: 15px;
    background: var(--home-li-color);
    box-shadow: 0 0 10px var(--home-box-li-color);
    text-align: '';
    color: var(--input-right-bottom-color);
    cursor: pointer;
    transition: all 0.5s;
    box-sizing: border-box;
  }
  .ps > li .des{
    width: 100%;
    height: 128px;
    background: var(--input-right-bottom-color);
  }
  /* 标签云 */
  .tag-cloud {
    position: relative;
    transform: translate(194%, 873%);
    width: 295px;
    padding: 5px;
    background: var(--home-tag-background-color);
    box-shadow: 0 0 10px var(--home-tag-color);
    border-radius: 8px;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20%;
  }
}
/* 手机尺寸 */
@media screen and (max-width: 1000px) {
  .w{
    max-width: 100%;
  }
  .home_jineng {
    display: flex;
    width: 100%;
    margin-left: -8%;
    margin-top: -15%;
  }
  .home_jineng > li {
    width: 80%;
    margin-left: 50px;
    margin-top: 15px;
    background: var(--home-li-color);
    box-shadow: 0 0 10px var(--home-box-li-color);
    text-align: '';
    color: var(--input-right-bottom-color);
    cursor: pointer;
    transition: all 0.5s;
    box-sizing: border-box;
  }
  .home_jineng > li .des{
    width: 100%;
    height: 128px;
    background: var(--input-right-bottom-color);
  }
  .html {
    display: flex;
    width: 100%;
    margin-left: -8%;
    margin-top: 0.5%;
  }
  .html > li {
    width: 80%;
    margin-left: 50px;
    margin-top: 15px;
    background: var(--home-li-color);
    box-shadow: 0 0 10px var(--home-box-li-color);
    text-align: '';
    color: var(--input-right-bottom-color);
    cursor: pointer;
    transition: all 0.5s;
    box-sizing: border-box;
  }
  .html > li .des{
    width: 100%;
    height: 128px;
    background: var(--input-right-bottom-color);
  }
  .Blender {
    display: flex;
    width: 100%;
    margin-left: -8%;
    margin-top: 0.5%;
    margin-bottom: 14%;
  }
  .Blender > li {
    width: 80%;
    margin-left: 50px;
    margin-top: 15px;
    background: var(--home-li-color);
    box-shadow: 0 0 10px var(--home-box-li-color);
    text-align: '';
    color: var(--input-right-bottom-color);
    cursor: pointer;
    transition: all 0.5s;
    box-sizing: border-box;
  }
  .Blender > li .des{
    width: 100%;
    height: 128px;
    background: var(--input-right-bottom-color);
  }
  .Crime {
    display: flex;
    width: 100%;
    margin-left: -8%;
    margin-top: 0.5%;
    margin-bottom: 14%;
  }
  .Crime > li {
    width: 80%;
    margin-left: 50px;
    margin-top: -96px;
    background: var(--home-li-color);
    box-shadow: 0 0 10px var(--home-box-li-color);
    text-align: '';
    color: var(--input-right-bottom-color);
    cursor: pointer;
    transition: all 0.5s;
    box-sizing: border-box;
  }
  .Crime > li .des{
    width: 100%;
    height: 128px;
    background: var(--input-right-bottom-color);
  }
  .ps {
    display: flex;
    width: 100%;
    margin-left: -8%;
    margin-top: 0.5%;
    margin-bottom: 35%;
  }
  .ps > li {
    width: 80%;
    margin-left: 50px;
    margin-top: -96px;
    background: var(--home-li-color);
    box-shadow: 0 0 10px var(--home-box-li-color);
    text-align: '';
    color: var(--input-right-bottom-color);
    cursor: pointer;
    transition: all 0.5s;
    box-sizing: border-box;
  }
  .ps > li .des{
    width: 100%;
    height: 128px;
    background: var(--input-right-bottom-color);
  }
  #popup{
    margin-top: -3%;
    margin-left: 78%;
  }
  .shousuo{
    margin-top: 1%;
    margin-left: 20%;
  }
  /* 标签云 */
  .tag-cloud {
    position: relative;
    transform: translate(188%, 890%);
    width: 295px;
    padding: 5px;
    background: var(--home-tag-background-color);
    box-shadow: 0 0 10px var(--home-tag-color);
    border-radius: 8px;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  .Version_cover {
    .center_box {
        width: 65%;
        height: 15rem;
        border-radius: 0.533333rem;
        background: rgba(123, 191, 212, 0.685);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
  }
  .search-results,
  .autocomplete-results {
    position: absolute;
    top: 2.7%; /* 紧贴在输入框的下方 */
    left: 15%;
    background: white;
    border: 1px solid #ccc;
    border-top: none;
    z-index: 1000; /* 确保结果悬浮在其他内容之上 */
    max-height: 300px; /* 设置最大高度，避免内容过长 */
    overflow-y: auto; /* 当内容超出时，出现滚动条 */
  }
}
/* 最小屏 */
@media screen and (max-width: 550px) {
  .w{
    max-width: 100%;
  }
  .home_jineng {
    display: flex;
    width: 100%;
    margin-left: -22%;
    margin-top: 0.5%;
  }
  .home_jineng > li {
    width: 80%;
    margin-left: 50px;
    margin-top: -50%;
    background: var(--home-li-color);
    box-shadow: 0 0 10px var(--home-box-li-color);
    text-align: '';
    color: var(--input-right-bottom-color);
    cursor: pointer;
    transition: all 0.5s;
    box-sizing: border-box;
  }
  .home_jineng > li .des{
    width: 100%;
    height: 128px;
    background: var(--input-right-bottom-color);
  }
  .html {
    display: flex;
    width: 100%;
    margin-left: -22%;
    margin-top: 0.5%;
  }
  .html > li {
    width: 80%;
    margin-left: 50px;
    margin-top: 15px;
    background: var(--home-li-color);
    box-shadow: 0 0 10px var(--home-box-li-color);
    text-align: '';
    color: var(--input-right-bottom-color);
    cursor: pointer;
    transition: all 0.5s;
    box-sizing: border-box;
  }
  .html > li .des{
    width: 100%;
    height: 128px;
    background: var(--input-right-bottom-color);
  }
  .Blender {
    display: flex;
    width: 100%;
    margin-left: -22%;
    margin-top: 0.5%;
    margin-bottom: 30%;
  }
  .Blender > li {
    width: 80%;
    margin-left: 50px;
    margin-top: 15px;
    background: var(--home-li-color);
    box-shadow: 0 0 10px var(--home-box-li-color);
    text-align: '';
    color: var(--input-right-bottom-color);
    cursor: pointer;
    transition: all 0.5s;
    box-sizing: border-box;
  }
  .Blender > li .des{
    width: 100%;
    height: 128px;
    background: var(--input-right-bottom-color);
  }
  .Crime {
    display: flex;
    width: 100%;
    margin-left: -22%;
    margin-top: -30%;
    margin-bottom: 30%;
  }
  .Crime > li {
    width: 80%;
    margin-left: 50px;
    margin-top: 15px;
    background: var(--home-li-color);
    box-shadow: 0 0 10px var(--home-box-li-color);
    text-align: '';
    color: var(--input-right-bottom-color);
    cursor: pointer;
    transition: all 0.5s;
    box-sizing: border-box;
  }
  .Crime > li .des{
    width: 100%;
    height: 128px;
    background: var(--input-right-bottom-color);
  }
  .ps {
    display: flex;
    width: 100%;
    margin-left: -22%;
    margin-top: -30%;
    margin-bottom: 30%;
  }
  .ps > li {
    width: 80%;
    margin-left: 50px;
    margin-top: 15px;
    margin-bottom: 40%;
    background: var(--home-li-color);
    box-shadow: 0 0 10px var(--home-box-li-color);
    text-align: '';
    color: var(--input-right-bottom-color);
    cursor: pointer;
    transition: all 0.5s;
    box-sizing: border-box;
  }
  .ps > li .des{
    width: 100%;
    height: 128px;
    background: var(--input-right-bottom-color);
  }
  /* 标签云 */
  .tag-cloud {
    position: relative;
    transform: translate(16%, 865%);
    width: 295px;
    padding: 5px;
    background: var(--home-tag-background-color);
    box-shadow: 0 0 10px var(--home-tag-color);
    border-radius: 8px;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20%;
  }
  #popup{
    margin-top: 5%;
    margin-left: 36%;
  }
  .shousuo{
    margin-top: 1%;
    margin-left: 4%;
  }
  .search-results,
  .autocomplete-results {
    position: absolute;
    top: 2.3%; /* 紧贴在输入框的下方 */
    left: 4%;
    background: white;
    border: 1px solid #ccc;
    border-top: none;
    z-index: 1000; /* 确保结果悬浮在其他内容之上 */
    max-height: 300px; /* 设置最大高度，避免内容过长 */
    overflow-y: auto; /* 当内容超出时，出现滚动条 */
  }
  .Version_cover {
    .center_box {
        width: 65%;
        height: 15rem;
        border-radius: 0.533333rem;
        background: rgba(123, 191, 212, 0.685);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
  }
}
</style>